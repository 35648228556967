<script setup lang="ts">
import {ProductsSubgroupTaxplan} from "@/common/services/Products/productsSubgroupTaxplanService";
import {FundingIntention} from "@/common/services/Products/fundingIntentionService";
import {computed} from "vue";
import {$inj} from "@/common/decorators/depinject";
import {FundingContributionUtils} from "@/common/services/funding/fundingContributionUtils";
import FormatFactory from "@/common/services/utils/FormatFactory";
import {isUndefined} from "lodash";

const fundingContributionUtils = $inj(FundingContributionUtils)
const formatFactory = $inj(FormatFactory)

const props = defineProps<{
  taxPlan: ProductsSubgroupTaxplan,
  fundingIntention: FundingIntention,
  groupName: string,
  tfaAccountType: 'IRA' | 'HSA'
}>()

const selectedYear = computed(() => props.fundingIntention.contributionYear)
const shouldLimitTheContributionLimits = computed(() => props.taxPlan.validateFundingIntentionContribLimits)

const contribLimit = computed(() => {
  return selectedYear.value == fundingContributionUtils.thisYear.toString()
    ? props.taxPlan.currentYearContribLimit
    : props.taxPlan.previousYearContribLimit
})

const catchupContribLimit = computed(() => {
  return selectedYear.value !== fundingContributionUtils.thisYear.toString()
      ? props.taxPlan.currentYearCatchupContribLimit
      : props.taxPlan.previousYearCatchupContribLimit
})

const ageLimit = computed(() => {
  return selectedYear.value !== fundingContributionUtils.thisYear.toString()
      ? props.taxPlan.previousYearCatchupAge
      : props.taxPlan.currentYearCatchupAge
})

const showDisclaimer = computed(() => {
  return !isUndefined(selectedYear.value) && !isUndefined(shouldLimitTheContributionLimits.value)
})
</script>
<template>
  <p v-if="showDisclaimer" data-cy="funding-intention-disclaimer">
    <b>
      For {{selectedYear}}, the total combined contributions across all of your {{props.groupName}}s must not exceed {{formatFactory.currency(contribLimit)}} for the year if you
      are under {{ageLimit}}, or {{formatFactory.currency(catchupContribLimit)}} if you are {{ageLimit}} or older.
    </b>
  </p>
</template>
