<script setup lang="ts">
import ProductSelectionIraIcon from '@/common/components/productSelection/product-selection-ira-icon.vue'
import {VsgCurrencyInput, VsgIconHeartbeat, VsgSelect} from '@jack-henry/jha-vue-components'
import {IFundingType} from '@/common/models/IFunding'
import {computed, DeepReadonly, onMounted, ref} from 'vue'
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import dayjs, {Dayjs} from 'dayjs'
import TFA_GROUPS from '@/common/components/productSelection/TfaGroupsEnum'
import type {IAccountTypeGroup} from '@/common/services/Products/accountGroupService'
import type {FundingIntention} from '@/common/services/Products/fundingIntentionService'
import FormatFactory from '@/common/services/utils/FormatFactory'
import {$inj, $injByInterface} from '@/common/decorators/depinject'
import type IAccountApplicant from '@/common/data/AccountServiceApplicant'
import {FundingContributionUtils} from '@/common/services/funding/fundingContributionUtils'
import ProductSelectionFundingIntentionDisclaimer from '@/common/components/productSelection/productSelectionFundingIntentionDisclaimer.vue'

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const formatFactory = $inj(FormatFactory)
const fundingContributionUtils = $inj(FundingContributionUtils)

const props = defineProps<{
  subgroup: IAccountTypeGroup
  fundingTypes: Map<number, Array<IFundingType>>
  accountIdsInGroup: Array<number>
}>()

const thisYear = dayjs().get('year')
const lastYear = dayjs().subtract(1, 'year').get('year')

const tfaAccountType = computed(() => {
  switch (props.subgroup.typeCode) {
    case TFA_GROUPS.ROTH_IRA:
    case TFA_GROUPS.TRADITIONAL_IRA:
      return 'IRA'
    case TFA_GROUPS.SINGLE_HSA:
    case TFA_GROUPS.FAMILY_HSA:
      return 'HSA'
  }
})

const groupName = computed(
  () => injection.values.productGroups.value.find((group) => group.typeCode == props.subgroup.typeCode)?.typeDescription
)

onMounted(() => {
  if (
    !injection.values.fundingIntentions.value.find((intention) => intention.subgroupCode == props.subgroup.typeCode)
  ) {
    injection.values.fundingIntentions.value.push({
      subgroupCode: props.subgroup.typeCode
    })
  }
})

const fundingIntention = computed<FundingIntention | undefined>({
  get() {
    return injection.values.fundingIntentions.value.find(
      (intention) => intention.subgroupCode == props.subgroup.typeCode
    )
  },
  set(value: FundingIntention | undefined) {
    injection.values.fundingIntentions.value.map((intention) => {
      if (intention.subgroupCode == value?.subgroupCode) {
        return value
      } else {
        return intention
      }
    })
  }
})

const taxPlan = computed(() => injection.values.productSubgroupTaxPlans.value.get(props.subgroup.typeCode))

const shouldShowPreviousYear = computed(() => {
  return dayjs(taxPlan.value?.previousYearContribCutoffDate).isAfter(dayjs())
})

const getFundingYears = computed(() => {
  return shouldShowPreviousYear.value
    ? [
        {name: lastYear, value: lastYear.toString()},
        {name: thisYear, value: thisYear.toString()}
      ]
    : [{name: thisYear, value: thisYear.toString()}]
})

const getFundingOptions = ref([
  {typeCode: 'CASH', typeDescription: 'Cash'},
  {typeCode: 'CHECK', typeDescription: 'Check'},
  {typeCode: 'INTERNAL_TRANSFER', typeDescription: 'Internal Transfer'},
  {typeCode: 'ACH', typeDescription: 'ACH Transfer'}
])

const accounts = computed(() => {
  return injection.values.accountList.value.filter((account) => {
    return Array.from(props.accountIdsInGroup).includes(account.accountId)
  })
})

const primaryApplicants = computed(() => {
  return accounts.value.reduce((acc, cur) => {
    return acc.concat(cur.applicants.filter((applicant) => applicant.primary))
  }, [] as Array<DeepReadonly<IAccountApplicant>>)
})

const ageOfTheFirstApplicantOnTheWorkspace = computed(() => {
  const dateOfBirth = injection.values.applicantList.value[0]?.person?.dateOfBirth

  if(!dateOfBirth) {
    return null
  }

  return dayjs().diff(dayjs(dateOfBirth), 'years')
})

const shouldLimitTheContributionLimits = computed(() => taxPlan.value?.validateFundingIntentionContribLimits)

const fundingLimit = computed(() => {
  if (!shouldLimitTheContributionLimits.value || !taxPlan.value || !fundingIntention.value || !ageOfTheFirstApplicantOnTheWorkspace.value) {
    return null
  }

  return fundingContributionUtils.getMaxFundingLimit(taxPlan.value, fundingIntention.value, ageOfTheFirstApplicantOnTheWorkspace.value)
})
</script>
<template>
  <product-selection-funding-intention-disclaimer
    v-if="taxPlan && fundingIntention && tfaAccountType"
    :taxPlan
    :groupName
    :tfaAccountType
    :fundingIntention/>

  <div class="subheader-block-subgroup jh-font-body-medium-1">
    <vsg-icon-heartbeat
      class="subgroup-icon"
      v-if="tfaAccountType == 'HSA'" />
    <product-selection-ira-icon
      class="subgroup-icon"
      v-if="tfaAccountType == 'IRA'" />
    &nbsp;{{ subgroup.typeDescription }}
  </div>

  <div v-if="fundingIntention">
    <vsg-select
      outline
      :value="fundingIntention.fundingMethod"
      @change="fundingIntention.fundingMethod = $event.detail"
      :options="getFundingOptions"
      labelKey="typeDescription"
      valueKey="typeCode"
      required
      empty-option-label=" "
      label="Funding method" />

    <vsg-select
      outline
      :value="fundingIntention.contributionYear"
      @change="fundingIntention.contributionYear = $event.detail"
      :options="getFundingYears"
      required
      empty-option-label=" "
      label="Contribution year" />

    <vsg-currency-input
      outline
      noDecimal
      min="0"
      :max="fundingLimit"
      v-model="fundingIntention.contributionAmount"
      :assistive-text="fundingLimit ? `Maximum contribution: ${formatFactory.currency(fundingLimit)}` : null"
      label="Contribution amount"
      required />
  </div>
</template>

<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';

.subheader-block-subgroup {
  display: flex;
  align-items: center;
  padding: var(--jh-space-200);
  margin-top: var(--jh-space-400);
  background: var(--secondary-content-background-color);
  color: var(--jh-color-content-secondary-enabled);
}

jha-form-select,
jha-form-currency-input {
  --error-text-top-margin: 0;
}

.subheader-block-subgroup .subgroup-icon {
  width: auto;
  height: 20px;
  fill: var(--jh-color-content-secondary-enabled);
}
</style>
