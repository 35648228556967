import {createRouter, createWebHistory} from 'vue-router'
import {WorkspaceStatesConstant} from '@/branchmanager/app/constant/WorkspaceStatesConstant'
import {useLocalStorage} from '@/common/stores/LocalStorageStore'
import {AdminStatesConstant} from '@/branchmanager/app/constant/AdminStatesConstant'
import Admin from "@/branchmanager/admin/admin.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: `/${WorkspaceStatesConstant.LOGIN.STATE}`
    },
    {
      path: `/${WorkspaceStatesConstant.LOGIN.STATE}`,
      name: WorkspaceStatesConstant.LOGIN.STATE,
      component: () => import('@/branchmanager/app/Login.vue')
    },
    {
      path: '',
      name: WorkspaceStatesConstant.ROOT.STATE,
      component: () => import('@/branchmanager/app/root.vue'),
      children: [
        {
          path: '',
          name: WorkspaceStatesConstant.LAUNCHPAD.STATE,
          component: () => import('@/branchmanager/app/SideNav.vue'),
          children: [
            {
              path: '',
              name: WorkspaceStatesConstant.DESKTOP.STATE,
              component: () => import('@/branchmanager/app/desktop/DesktopState.vue')
            },
            {
              path: '/dashboard',
              name: WorkspaceStatesConstant.DASHBOARD.STATE,
              component: () => import('@/branchmanager/app/dashboard/DashboardState.vue'),
              redirect: {name: WorkspaceStatesConstant.DASHBOARD_OVERVIEW.STATE},
              children: [
                {
                  path: 'overview',
                  name: WorkspaceStatesConstant.DASHBOARD_OVERVIEW.STATE,
                  component: () => import('@/branchmanager/app/dashboard/DashboardView.vue')
                },
                {
                  path: 'report/:reportId/detail',
                  name: WorkspaceStatesConstant.DASHBOARD_REPORT_SINGLE_VIEW.STATE,
                  component: () => import('@/branchmanager/app/dashboard/DashboardReportSingleView.vue')
                },
                {
                  path: 'report/:reportId/summary',
                  name: WorkspaceStatesConstant.DASHBOARD_REPORT_MULTI_VIEW.STATE,
                  component: () => import('@/branchmanager/app/dashboard/DashboardReportMultiView.vue')
                },
                {
                  path: 'report/:reportId/:type/schedule',
                  name: WorkspaceStatesConstant.DASHBOARD_REPORT_EMAIL_SCHEDULES.STATE,
                  component: () => import('@/branchmanager/app/dashboard/schedules/ReportEmailSchedules.vue')
                }
              ]
            },
            {
              path: '/admin',
              name: AdminStatesConstant.ADMIN.STATE,
              component: () => import('@/branchmanager/admin/admin.vue'),
              children: [
                {
                  path: 'zipcode',
                  name: AdminStatesConstant.ZIP_CODE.STATE,
                  redirect: {name: AdminStatesConstant.ZIP_CODE_WHITELIST.STATE},
                  component: () => import('@/branchmanager/admin/zipCode/zipCodeAdmin.vue'),
                  children: [
                    {
                      path: 'regions',
                      name: AdminStatesConstant.ZIP_CODE_REGIONS_LIST.STATE,
                      component: () => import('@/branchmanager/admin/zipCode/zipCodeRegionList.vue')
                    },
                    {
                      path: 'regions/:regionId',
                      name: AdminStatesConstant.ZIP_CODE_REGION.STATE,
                      component: () => import('@/branchmanager/admin/zipCode/zipCodeRegion.vue')
                    },
                    {
                      path: 'whitelist',
                      name: AdminStatesConstant.ZIP_CODE_WHITELIST.STATE,
                      component: () => import('@/branchmanager/admin/zipCode/zipCodeWhitelist.vue')
                    }
                  ]
                },
                {
                  path: 'funding',
                  name: AdminStatesConstant.FUNDING.STATE,
                  redirect: {name: AdminStatesConstant.FUNDING_ACH.STATE},
                  component: () => import('@/branchmanager/admin/funding/fundingState.vue'),
                  children: [
                    {
                      path: 'ach',
                      name: AdminStatesConstant.FUNDING_ACH.STATE,
                      component: () => import('@/branchmanager/admin/funding/fundingAchState.vue')
                    },
                    {
                      path: 'verification',
                      name: AdminStatesConstant.FUNDING_VERIFICATION.STATE,
                      component: () => import('@/branchmanager/admin/funding/fundingVerificationState.vue')
                    },
                    {
                      path: 'holidays',
                      name: AdminStatesConstant.FUNDING_HOLIDAYS.STATE,
                      component: () => import('@/branchmanager/admin/funding/fundingHolidaysState.vue')
                    },
                    {
                      path: 'providers',
                      name: AdminStatesConstant.FUNDING_PROVIDERS.STATE,
                      component: () => import('@/branchmanager/admin/funding/fundingProvidersState.vue')
                    },
                    {
                      path: 'providers/:providerCode',
                      name: AdminStatesConstant.FUNDING_PROVIDERS_EDITOR.STATE,
                      component: () => import('@/branchmanager/admin/funding/fundingProviderEditorState.vue')
                    }
                  ]
                },
                {
                  path: 'ocr',
                  name: AdminStatesConstant.OCR.STATE,
                  component: () => import('@/branchmanager/admin/OCR/ocrState.vue')
                },
                {
                  path: 'relationships',
                  name: AdminStatesConstant.RELATIONSHIPS.STATE,
                  component: () => import('@/branchmanager/admin/relationships/relationships.vue')
                },
                {
                  path: 'business',
                  name: AdminStatesConstant.BUSINESS.STATE,
                  redirect: {
                    name: AdminStatesConstant.BUSINESS_DOCUMENTS.STATE
                  },
                  component: () => import('@/branchmanager/admin/business/businessState.vue'),
                  children: [
                    {
                      path: 'type',
                      name: AdminStatesConstant.BUSINESS_TYPES.STATE,
                      component: () => import('@/branchmanager/admin/business/businessTypes.vue')
                    },
                    {
                      path: 'documents',
                      name: AdminStatesConstant.BUSINESS_DOCUMENTS.STATE,
                      component: () => import('@/branchmanager/admin/business/businessDocuments.vue')
                    }
                  ]
                },
                {
                  path: 'pigeon',
                  name: AdminStatesConstant.PIGEON_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/pigeon/pigeonAdmin.vue'),
                  redirect: {
                    name: AdminStatesConstant.PIGEON_CARRIER_LIST.STATE
                  },
                  children: [
                    {
                      path: 'properties',
                      name: AdminStatesConstant.PIGEON_PROPERTIES.STATE,
                      component: () => import('@/branchmanager/admin/pigeon/pigeonProperties.vue')
                    },
                    {
                      path: 'carriers',
                      name: AdminStatesConstant.PIGEON_CARRIER_LIST.STATE,
                      component: () => import('@/branchmanager/admin/pigeon/pigeonCarrierList.vue')
                    },
                    {
                      path: 'serviceCode/:serviceCode/carrier/:carrierName',
                      name: AdminStatesConstant.PIGEON_CARRIER.STATE,
                      component: () => import('@/branchmanager/admin/pigeon/pigeonCarrier.vue')
                    },
                    {
                      path: 'serviceCode/:serviceCode/carrier/:carrierName/email/:templateName',
                      name: AdminStatesConstant.PIGEON_EMAIL.STATE,
                      component: () => import('@/branchmanager/admin/pigeon/pigeonEmail.vue')
                    },
                    {
                      path: 'serviceCode/:serviceCode/carrier/:carrierName/sms/:templateName',
                      name: AdminStatesConstant.PIGEON_SMS.STATE,
                      component: () => import('@/branchmanager/admin/pigeon/pigeonSms.vue')
                    }
                  ]
                },
                {
                  path: 'disclosures/:disclosureCode?',
                  name: AdminStatesConstant.DISCLOSURES.STATE,
                  component: () => import('@/branchmanager/admin/disclosures/disclosure.vue')
                },
                {
                  path: 'oauth',
                  name: AdminStatesConstant.OAUTH.STATE,
                  redirect: {name: AdminStatesConstant.OAUTH_LIST.STATE},
                  component: () => import('@/branchmanager/admin/oauth/oauth.vue'),
                  children: [
                    {
                      path: '',
                      name: AdminStatesConstant.OAUTH_LIST.STATE,
                      component: () => import('@/branchmanager/admin/oauth/oauthList.vue')
                    },
                    {
                      path: ':oauthUUID',
                      name: AdminStatesConstant.OAUTH_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/oauth/oauthEdit.vue')
                    }
                  ]
                },
                {
                  path: 'synergy',
                  name: AdminStatesConstant.SYNERGY.STATE,
                  component: () => import('@/branchmanager/admin/synergy/synergy.vue'),
                  redirect: {
                    name: AdminStatesConstant.SYNERGY_QUEUED.STATE
                  },
                  children: [
                    {
                      path: 'completed',
                      name: AdminStatesConstant.SYNERGY_COMPLETED.STATE,
                      component: () => import('@/branchmanager/admin/synergy/synergy_completed.vue')
                    },
                    {
                      path: 'failed',
                      name: AdminStatesConstant.SYNERGY_FAILED.STATE,
                      component: () => import('@/branchmanager/admin/synergy/synergy_failed.vue')
                    },
                    {
                      path: 'queued',
                      name: AdminStatesConstant.SYNERGY_QUEUED.STATE,
                      component: () => import('@/branchmanager/admin/synergy/synergy_queued.vue')
                    },
                    {
                      path: 'index-mapping',
                      name: AdminStatesConstant.SYNERGY_INDEX_MAPPING.STATE,
                      component: () => import('@/branchmanager/admin/synergy/synergy_index_mapping.vue')
                    },
                    {
                      path: 'properties',
                      name: AdminStatesConstant.SYNERGY_PROPERTIES.STATE,
                      component: () => import('@/branchmanager/admin/synergy/synergy_properties.vue')
                    }
                  ]
                },
                {
                  path: 'wolterskluwer',
                  name: AdminStatesConstant.WOLTERS_KLUWER.STATE,
                  redirect: {name: AdminStatesConstant.WOLTERS_KLUWER_PROPERTIES.STATE},
                  component: () => import('@/branchmanager/admin/woltersKluwer/woltersKluwer.vue'),
                  children: [
                    {
                      path: 'properties',
                      name: AdminStatesConstant.WOLTERS_KLUWER_PROPERTIES.STATE,
                      component: () => import('@/branchmanager/admin/woltersKluwer/wkPropertiesState.vue')
                    },
                    {
                      path: 'organizations',
                      name: AdminStatesConstant.WOLTERS_KLUWER_ORGANIZATIONS.STATE,
                      component: () => import('@/branchmanager/admin/woltersKluwer/wkOrganizationsState.vue')
                    },
                    {
                      path: 'roles',
                      name: AdminStatesConstant.WOLTERS_KLUWER_ROLES.STATE,
                      component: () => import('@/branchmanager/admin/woltersKluwer/wkRolesState.vue')
                    },
                    {
                      path: 'products',
                      name: AdminStatesConstant.WOLTERS_KLUWER_PRODUCTS.STATE,
                      component: () => import('@/branchmanager/admin/woltersKluwer/wkProductsState.vue')
                    }
                  ]
                },
                {
                  path: 'rule',
                  name: AdminStatesConstant.RULES_ADMIN.STATE,
                  redirect: {name: AdminStatesConstant.RULE_CATEGORY_LIST.STATE},
                  component: () => import('@/branchmanager/admin/rules/rulesAdmin.vue'),
                  children: [
                    {
                      path: '',
                      name: AdminStatesConstant.RULE_CATEGORY_LIST.STATE,
                      component: () => import('@/branchmanager/admin/rules/child-states/category/ruleCategoryList.vue')
                    },
                    {
                      path: 'category/:categoryId',
                      name: AdminStatesConstant.RULES_CATEGORY.STATE,
                      component: () => import('@/branchmanager/admin/rules/child-states/category/ruleCategory.vue')
                    },
                    {
                      path: 'edit/:ruleUUID?/:draftId?',
                      name: AdminStatesConstant.RULES_EDITOR.STATE,
                      component: () => import('@/branchmanager/admin/rules/child-states/edit/ruleEdit.vue')
                    }
                  ]
                },
                {
                  path: 'verbiage',
                  name: AdminStatesConstant.VERBIAGE.STATE,
                  redirect: {name: AdminStatesConstant.VERBIAGE_CATEGORY_LIST.STATE},
                  component: () => import('@/branchmanager/admin/verbiage/verbiageState.vue'),
                  children: [
                    {
                      path: '',
                      name: AdminStatesConstant.VERBIAGE_CATEGORY_LIST.STATE,
                      component: () => import('@/branchmanager/admin/verbiage/verbiageCategoryList.vue')
                    },
                    {
                      path: 'category/:categoryId',
                      name: AdminStatesConstant.VERBIAGE_CATEGORY.STATE,
                      component: () => import('@/branchmanager/admin/verbiage/verbiageCategory.vue')
                    },
                    {
                      path: 'search/:searchTerm',
                      name: AdminStatesConstant.VERBIAGE_SEARCH.STATE,
                      component: () => import('@/branchmanager/admin/verbiage/verbiageSearch.vue')
                    }
                  ]
                },
                {
                  path: 'service',
                  name: AdminStatesConstant.ADMIN_SERVICE_LIST.STATE,
                  component: () => import('@/branchmanager/admin/services/servicesListState.vue'),
                  children: [
                    {
                      path: ':serviceCode',
                      name: AdminStatesConstant.ADMIN_SERVICE.STATE,
                      component: () => import('@/branchmanager/admin/services/serviceState.vue')
                    },
                    {
                      path: 'images',
                      name: AdminStatesConstant.ADMIN_SERVICE_IMAGES.STATE,
                      component: () => import('@/branchmanager/admin/services/manageImagesState.vue')
                    },
                  ]
                },
                {
                  path: 'users',
                  name: AdminStatesConstant.USERS.STATE,
                  component: () => import('@/branchmanager/admin/users/usersRoot.vue'),
                  redirect: {
                    name: AdminStatesConstant.USERS_LIST.STATE
                  },
                  children: [
                    {
                      path: 'users',
                      name: AdminStatesConstant.USERS_LIST.STATE,
                      component: () => import('@/branchmanager/admin/users/users.vue')
                    },
                    {
                      path: 'login/:login/userId/:userId?',
                      name: AdminStatesConstant.USER_DETAILS.STATE,
                      component: () => import('@/branchmanager/admin/users/user/userDetails.vue')
                    },
                    {
                      path: 'group/:groupId',
                      name: AdminStatesConstant.GROUP_DETAILS.STATE,
                      component: () => import('@/branchmanager/admin/users/group/groupDetails.vue')
                    },
                    {
                      path: 'password',
                      name: AdminStatesConstant.PASSWORD_SETTINGS.STATE,
                      component: () => import('@/branchmanager/admin/disclosures/disclosure.vue')
                    }
                  ]
                },
                {
                  path: 'cip',
                  name: AdminStatesConstant.CIP_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/cip/cipAdmin.vue'),
                  redirect: {name: AdminStatesConstant.CIP_ADMIN_LIST.STATE},
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.CIP_ADMIN_LIST.STATE,
                      component: () => import('@/branchmanager/admin/cip/cipAdminList.vue')
                    },
                    {
                      path: ':cipServiceCode',
                      name: AdminStatesConstant.CIP_ADMIN_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/cip/cipAdminEdit.vue')
                    }
                  ]
                },
                {
                  path: 'fields',
                  name: AdminStatesConstant.FIELDS_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/fields/fieldsAdmin.vue'),
                  redirect: {name: AdminStatesConstant.FIELDS_ADMIN_LIST.STATE},
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.FIELDS_ADMIN_LIST.STATE,
                      component: () => import('@/branchmanager/admin/fields/fieldsAdminList.vue')
                    },
                    {
                      path: 'category/:categoryName',
                      name: AdminStatesConstant.FIELDS_ADMIN_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/fields/fieldsAdminEdit.vue'),
                      children: [
                        {
                          path: 'applicant/:applicantId/employee/:employeeId/applicant',
                          name: AdminStatesConstant.FIELDS_ADMIN_APPLICANT.STATE,
                          component: () => import('@/branchmanager/admin/fields/components/fieldsAdminEditTab.vue')
                        },
                        {
                          path: 'applicant/:applicantId/employee/:employeeId/employee',
                          name: AdminStatesConstant.FIELDS_ADMIN_EMPLOYEE.STATE,
                          component: () => import('@/branchmanager/admin/fields/components/fieldsAdminEditTab.vue')
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'properties',
                  name: AdminStatesConstant.PROPERTIES_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/properties/propertiesAdmin.vue'),
                  redirect: {name: AdminStatesConstant.PROPERTIES_ADMIN_LIST.STATE},
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.PROPERTIES_ADMIN_LIST.STATE,
                      component: () => import('@/branchmanager/admin/properties/propertiesAdminList.vue')
                    },
                    {
                      path: 'serviceCode/:serviceCode',
                      name: AdminStatesConstant.PROPERTIES_ADMIN_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/properties/propertiesAdminEdit.vue')
                    }
                  ]
                },
                {
                  path: 'questionnaire',
                  name: AdminStatesConstant.QUESTIONNAIRE_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/questionnaire/questionnaireAdmin.vue'),
                  redirect: {name: AdminStatesConstant.QUESTIONNAIRE_ADMIN_LIST.STATE},
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.QUESTIONNAIRE_ADMIN_LIST.STATE,
                      component: () => import('@/branchmanager/admin/questionnaire/questionnaireList.vue'),
                      children: []
                    },
                    {
                      path: 'questionnaireId/:questionnaireId/draft/:draftId?',
                      name: AdminStatesConstant.QUESTIONNAIRE_ADMIN_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/questionnaire/edit/questionnaireEdit.vue')
                    },
                    {
                      path: 'questionnaireId/:questionnaireId/draft/:draftId/diagram',
                      name: AdminStatesConstant.QUESTIONNAIRE_ADMIN_DIAGRAM_PREVIEW.STATE,
                      component: () => import('@/branchmanager/admin/questionnaire/edit/questionnaireEdit.vue')
                    },
                    {
                      path: 'choiceCatalog',
                      name: AdminStatesConstant.QUESTIONNAIRE_ADMIN_CHOICE_CATALOG.STATE,
                      component: () => import('@/branchmanager/admin/questionnaire/questionnaireChoiceCatalog.vue'),
                      children: []
                    },
                    {
                      path: 'choiceCatalog/url/:url/categoryName/:categoryName',
                      name: AdminStatesConstant.QUESTIONNAIRE_ADMIN_CHOICE_CATALOG_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/questionnaire/choiceCatalogEdit.vue'),
                      children: []
                    }
                  ]
                },
                {
                  path: 'export',
                  name: AdminStatesConstant.DOCUMENT_EXPORTS_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/documentExport/documentExportsAdmin.vue'),
                  redirect: {name: AdminStatesConstant.DOCUMENT_EXPORTS_ADMIN_LIST.STATE},
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.DOCUMENT_EXPORTS_ADMIN_LIST.STATE,
                      component: () => import('@/branchmanager/admin/documentExport/documentExportAdminList.vue')
                    }
                  ]
                },
                {
                  path: 'mapping',
                  name: AdminStatesConstant.ADMIN_MAPPING.STATE,
                  component: () => import('@/branchmanager/admin/mapping/mappingPage.vue'),
                  children: [
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_personTypesMapping`,
                      path: 'personTypesMapping',
                      component: () => import('@/branchmanager/admin/mapping/personTypesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_addressTypesMapping`,
                      path: 'addressTypesMapping',
                      component: () => import('@/branchmanager/admin/mapping/addressTypesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_citizenCodesMapping`,
                      path: 'citizenCodesMapping',
                      component: () => import('@/branchmanager/admin/mapping/citizenCodesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_educationCodeMapping`,
                      path: 'educationCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/educationCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_occupationCodeMapping`,
                      path: 'occupationCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/occupationCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_maritalStatusCodeMapping`,
                      path: 'maritalStatusCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/maritalStatusCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_branchMapping`,
                      path: 'branchMapping',
                      component: () => import('@/branchmanager/admin/mapping/branchMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_officerCodeMapping`,
                      path: 'officerCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/officerCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_languageCodeMapping`,
                      path: 'languageCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/languageCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_incomeCodeMapping`,
                      path: 'incomeCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/incomeCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_phoneTypeMapping`,
                      path: 'phoneTypeMapping',
                      component: () => import('@/branchmanager/admin/mapping/phoneTypeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_emailTypesMapping`,
                      path: 'emailTypesMapping',
                      component: () => import('@/branchmanager/admin/mapping/emailTypesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_genderCodeMapping`,
                      path: 'genderCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/genderCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_mailTypesMapping`,
                      path: 'mailTypesMapping',
                      component: () => import('@/branchmanager/admin/mapping/mailTypesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_raceCodesMapping`,
                      path: 'raceCodesMapping',
                      component: () => import('@/branchmanager/admin/mapping/raceCodesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_ethnicityCodeMapping`,
                      path: 'ethnicityCodeMapping',
                      component: () => import('@/branchmanager/admin/mapping/ethnicityCodeMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_employmentTypesMapping`,
                      path: 'employmentTypesMapping',
                      component: () => import('@/branchmanager/admin/mapping/employmentTypesMapping.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_AccountClassificationTypes`,
                      path: 'AccountClassificationTypes',
                      component: () => import('@/branchmanager/admin/mapping/AccountClassificationTypes.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_AccountGroupProductTypes`,
                      path: 'AccountGroupProductTypes',
                      component: () => import('@/branchmanager/admin/mapping/AccountGroupProductTypes.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_ExistingCustomerAccountProducts`,
                      path: 'ExistingCustomerAccountProducts',
                      component: () => import('@/branchmanager/admin/mapping/ExistingCustomerAccountProducts.vue')
                    },
                    {
                      name: `${AdminStatesConstant.ADMIN_MAPPING.STATE}_AccountRoleTypes`,
                      path: 'AccountRoleTypes',
                      component: () => import('@/branchmanager/admin/mapping/AccountRoleTypes.vue')
                    }
                  ]
                },
                {
                  path: 'product',
                  name: AdminStatesConstant.ADMIN_PRODUCTS.STATE,
                  redirect: {name: AdminStatesConstant.ADMIN_PRODUCTS_LIST.STATE},
                  component: () => import('@/branchmanager/admin/products/adminProducts.vue'),
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.ADMIN_PRODUCTS_LIST.STATE,
                      component: () => import('@/branchmanager/admin/products/productList.vue')
                    },
                    {
                      path: 'productId/:productId',
                      name: AdminStatesConstant.ADMIN_PRODUCT.STATE,
                      redirect: {name: AdminStatesConstant.ADMIN_PRODUCT_OVERVIEW.STATE},
                      component: () => import('@/branchmanager/admin/products/adminProduct.vue'),
                      children: [
                        {
                          path: 'overview',
                          name: AdminStatesConstant.ADMIN_PRODUCT_OVERVIEW.STATE,
                          component: () => import('@/branchmanager/admin/products/tabs/productOverview.vue')
                        },
                        {
                          path: 'funding',
                          name: AdminStatesConstant.ADMIN_PRODUCT_FUNDING.STATE,
                          component: () => import('@/branchmanager/admin/products/tabs/productFunding.vue')
                        },
                        {
                          path: 'overrides',
                          name: AdminStatesConstant.ADMIN_PRODUCT_OVERRIDES.STATE,
                          component: () => import('@/branchmanager/admin/products/tabs/productOverrides.vue')
                        }
                      ]
                    },
                    {
                      path: 'intentions',
                      name: AdminStatesConstant.ADMIN_PRODUCTS_INTENTIONS.STATE,
                      component: () => import('@/branchmanager/admin/products/accountIntentions.vue')
                    },
                    {
                      path: 'group',
                      name: AdminStatesConstant.ADMIN_PRODUCTS_GROUPS.STATE,
                      component: () => import('@/branchmanager/admin/products/accountGroups.vue')
                    },
                    {
                      path: 'mapping',
                      name: AdminStatesConstant.ADMIN_PRODUCTS_MAPPING.STATE,
                      component: () => import('@/branchmanager/admin/products/productMapping.vue')
                    }
                  ]
                },
                {
                  path: 'promo-codes',
                  name: AdminStatesConstant.PROMO_CODES.STATE,
                  component: () => import('@/branchmanager/admin/promoCodes/promoCodes.vue')
                },
                {
                  path: 'scheduled-tasks',
                  name: AdminStatesConstant.SCHEDULED_TASKS.STATE,
                  redirect: {name: AdminStatesConstant.SCHEDULED_TASKS_LIST.STATE},
                  component: () => import('@/branchmanager/admin/scheduledTasks/scheduledTasks.vue'),
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.SCHEDULED_TASKS_LIST.STATE,
                      component: () => import('@/branchmanager/admin/scheduledTasks/scheduledTasksList.vue')
                    },
                    {
                      path: 'task/:taskId',
                      name: AdminStatesConstant.SCHEDULED_TASK.STATE,
                      redirect: {name: AdminStatesConstant.SCHEDULED_TASK_OVERVIEW.STATE},
                      component: () => import('@/branchmanager/admin/scheduledTasks/scheduledTask.vue'),
                      children: [
                        {
                          name: AdminStatesConstant.SCHEDULED_TASK_OVERVIEW.STATE,
                          path: 'overview',
                          component: () => import('@/branchmanager/admin/scheduledTasks/overview.vue')
                        },
                        {
                          name: AdminStatesConstant.SCHEDULED_TASK_LOGS.STATE,
                          path: 'logs',
                          component: () => import('@/branchmanager/admin/scheduledTasks/logs.vue')
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'workflow',
                  name: AdminStatesConstant.WORKFLOW_ADMIN.STATE,
                  component: () => import('@/branchmanager/admin/workflow/workflowAdmin.vue'),
                  redirect: {name: AdminStatesConstant.WORKFLOW_ADMIN_LIST.STATE},
                  children: [
                    {
                      path: 'list',
                      name: AdminStatesConstant.WORKFLOW_ADMIN_LIST.STATE,
                      component: () => import('@/branchmanager/admin/workflow/workflowList.vue'),
                      children: []
                    },
                    {
                      path: 'workflowCode/:workflowCode?/:workflowName?',
                      name: AdminStatesConstant.WORKFLOW_ADMIN_EDIT.STATE,
                      component: () => import('@/branchmanager/admin/workflow/edit/workflowEdit.vue')
                    }
                  ]
                },
              ]
            }
          ]
        },
        {
          path: `/${WorkspaceStatesConstant.WORKSPACE.STATE}/:workspaceUUID`,
          name: WorkspaceStatesConstant.WORKSPACE.STATE,
          component: () => import('@/branchmanager/app/workspace/WorkspaceState.vue'),
          children: [
            {
              path: ``,
              name: WorkspaceStatesConstant.WORKSPACE_DETAIL.STATE,
              component: () => import('@/branchmanager/app/workspace/WorkspaceDetailState.vue')
            },
            {
              path: ``,
              name: WorkspaceStatesConstant.SEARCH_CUSTOMER.STATE,
              component: () => import('@/branchmanager/app/workspace/customer-search/CustomerSearchState.vue')
            },
            {
              path: `/${WorkspaceStatesConstant.WORKSPACE.STATE}/:workspaceUUID/${WorkspaceStatesConstant.WORKFLOW.STATE}/:applicantType/:workspaceEntityId`,
              name: WorkspaceStatesConstant.WORKFLOW.STATE,
              component: () => import('@/branchmanager/app/workspace/WorkflowState.vue'),
              children: [
                {
                  path: ``,
                  name: 'INTERIM',
                  component: () => import('@/branchmanager/app/workspace/InterimState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.PERSONINFO.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/person/personInfo/PersonInfoStateDecorator.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.ADDRESS.STATE,
                  component: () => import('@/branchmanager/app/workspace/applicant/shared/address/AddressState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.REFERENCES.STATE,
                  component: () => import('@/branchmanager/app/workspace/applicant/shared/references/ReferencesState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.INITIAL_DISCLOSURE.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/shared/disclosure/DisclosureState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.PERSON_RELATIONSHIP.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/enrollment/relationships/RelationshipState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.EMAIL.STATE,
                  component: () => import('@/branchmanager/app/workspace/applicant/shared/email/EmailState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.PHONE.STATE,
                  component: () => import('@/branchmanager/app/workspace/applicant/shared/phone/PhoneState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.IDENTIFICATION.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/shared/identification/IdentificationState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.INCOME.STATE,
                  component: () => import('@/branchmanager/app/workspace/applicant/shared/income/incomeState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.FUNDING.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/funding/FundingState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.BRANCH_SELECTION.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/enrollment/branchSelection/BranchSelectionState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.BENEFICIARY_INFO.STATE,
                  component: () =>
                      import('@/branchmanager/app/workspace/enrollment/beneficiaryInfo/beneficiaryInfo.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.PRODUCT_SELECTION.STATE,
                  component: () =>
                    import(
                      '@/branchmanager/app/workspace/enrollment/productselection/ProductSelectionStateDecorator.vue'
                    )
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.ROLES.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/roles/RoleState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.MAILING.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/mailing/MailingState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.SERVICES.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/services/ServicesState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.CIP.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/cip/CipState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.SUMMARY.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/summary/SummaryState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.FINAL_DISCLOSURE.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/enrollment/finalDisclosure/FinalDisclosureState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.END.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/end/EndState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.BUSINESS_INFO.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/business/businessInfo/BusinessInfoState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.DOCUMENT.STATE,
                  component: () => import('@/branchmanager/app/workspace/applicant/shared/documents/DocumentsState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.BUSINESS_OWNERSHIP.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/ownership/OwnershipState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.BUSINESS_RESOLUTION.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/enrollment/resolution/businessResolutionState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.DEMOGRAPHICS.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/person/demographics/DemographicsState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.EMPLOYMENT.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/person/employment/EmploymentState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.WOLTERS_KLUWER.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/wk/WoltersKluwerState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.RIGHT_OF_SURVIVORSHIP.STATE,
                  component: () => import('@/branchmanager/app/workspace/enrollment/right/RightOfSurvivorshipState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.ADDITIONAL_INCOME.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/person/additionalIncome/additionalIncomeState.vue')
                },
                {
                  path: ``,
                  name: WorkspaceStatesConstant.EMPLOYMENT_HISTORY.STATE,
                  component: () =>
                    import('@/branchmanager/app/workspace/applicant/employmentAndIncomeHistory/employmentAndIncomeHistoryState.vue')
                }
              ]
            }
          ]
        }
      ]
    }
  ]
})

function setupCommonAuthRouteGuard() {
  router.beforeEach((to, from, next) => {
    const localStore = useLocalStorage()

    const nameOrPath = to.name || to.path?.substring(1)
    if (nameOrPath === '' || nameOrPath === WorkspaceStatesConstant.LOGIN.STATE) {
      next()
    } else if (!localStore.apitoken || localStore.apitoken.length === 0) {
      next({name: WorkspaceStatesConstant.LOGIN.STATE})
    } else {
      next()
    }
  })
}
setupCommonAuthRouteGuard()

export default router
