<script lang="ts">
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { BroadcastService, LanguageFactory } from "@/common/services/services.module";
import type IEmpWorkspaceMetadataStore from "@/branchmanager/stores/IEmpWorkspaceMetadataStore";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import { defineComponent, type PropType} from "vue";
import type IApplicant from "@/common/models/IApplicant";
import EnrollmentValidationStates from "@/branchmanager/app/enums/enrollmentValidationStates";
import EnrollmentValidationFactory from "@/branchmanager/services/EnrollmentValidationFactory";
import FormValidationTypes from "@/branchmanager/app/enums/formValidationTypes";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";
import ApplicantValidationFactory from "@/common/services/Applicant/applicantValidationFactory";
import ApplicantValidationStates from "@/branchmanager/app/enums/applicantValidationStates";
import type { Detail } from "@/common/models/IStateValidationResponse";
import type INotificationFactory from "@/common/services/INotificationFactory";
import { workspaceStatusConstant } from "@/branchmanager/app/constant/workspaceStatusConstant";
import BltForm from "@/common/components/bltForm/bltForm.vue";
import { ModalUtil } from "@/common/utils/ModalUtil";

export default defineComponent({
  name: "bltEmpForm",
  components: { BltForm },
  emits: ["continueClicked", "backClicked", "searchBtnClicked"],
  props: {
    alwaysValidate: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: () => false
    },
    // This expects a promise returning function, upon resolving, a validation call will be made, showing a loading animation.
    // upon a successful validation the form will move to the next tab.
    // upon an error validation, the form will display the error and the user will not move forward.
    beforeContinue: {
      type: Function as unknown as () => () => Promise<void>,
      required: false,
      default: () => () => Promise.resolve(),
    },
    //When Continue should be disabled
    //Back button's function
    back: { type: Function },
    //When Continue should be disabled
    continueReplacedWithSecondary: {
      type: Boolean,
      default: () => false
    },
    alternateBackEnabled: {
      type: Boolean,
      default: () => false
    },
    //Enable an alternate back text & function
    alternateBackText: {
      type: String
    },
    //Text on alternate back button
    alternateBackFunc: {
      type: Function
    },
    //Function on alternate back button
    //renamed: backDisabled
    backDisabled: {
      type: Boolean,
      default: () => false
    },
    //When Back should be disabled
    disabled: {
      type: Boolean,
      default: () => false
    },
    //Same as ContinueDisabled, but effects both back and continue
    secondaryAction: {
      type: Function
    },
    //Function when clicking the secondary button
    secondaryActionText: {
      type: String
    },
    //Secondary button text
    secondaryActionActive: {
      type: Object
    },
    //When the Secondary button should be active
    secondaryActionClass: {
      type: String
    },
    //Class(es) for the secondary button
    //renamed: secondaryDisabled
    continueDisabledSecondary: {
      type: Boolean,
      default: () => false
    },
    //Disables secondary button
    loading: {
      type: Boolean,
      default: () => false
    },
    //Disable both continue and back while this is true
    hideInfoBlock: {
      type: Boolean,
      default: () => false
    },
    //Hide the info block if this is true
    hideNavigation: {
      type: Boolean,
      default: () => false
    },
    bltUiText: {
      type: String
    },
    hideBackButton: {
      type: Boolean,
      default: () => false
    },
    hideContinueButton: {
      type: Boolean,
      default: () => false
    },
    showDeterminateProgressBar: {
      type: Boolean,
      default: () => false
    },
    searchIcon: {
      type: Boolean,
      default: () => false
    },
    innerScreenButtonEnabled: {
      type: Boolean,
      default: () => false
    },
    //Inner screen additional button
    innerScreenBtnFunc: {
      type: Function
    },
    contentCardClass: {
      type: String
    },
    dividerLine: {
      type: Boolean,
      default: () => false
    },
    contentMarginClass: {
      type: String
    },
    hideContinueLoader: {
      type: Boolean,
      default: () => true
    },
    staticTitle: {
      type: Boolean,
      default: () => false
    },
    contentHeightFix: {
      type: Boolean,
      default: () => false
    },
    loaderMessages: {
      type: Array,
      default: () => []
    },
    secondaryTitle: {
      type: String
    },
    hideErrorNotification: {
      type: Boolean,
      default: () => false
    },
    description: {
      type: String,
      required: false
    },
    currentStateCssClass: {
      type: String,
      default: () => ""
    },
    secondaryRightSideText: {
      type: String
    },
    validation: {
      type: String as () => keyof typeof EnrollmentValidationStates | keyof typeof ApplicantValidationStates, //todo(mikol): split this up...
      required: true,
    },
    validationType: { // todo(mikol): split bltEmpForm into bltApplicantForm and bltEnrollment form, this is bloat.
      type: String as () => keyof typeof FormValidationTypes,
      required: true,
    },
    workspaceUuid: {
      type: String,
      required: true,
    },
    enrollmentId: {
      type: Number,
    },
    applicantId: {
      type: Number,
    }
  },
  data() {
    return {
      validating: false,
      pristine: true,
    }
  },
  setup() {
    return {
      enrollmentValidationFactory: $inj(EnrollmentValidationFactory),
      applicantValidationFactory: $inj(ApplicantValidationFactory),
      workspaceStore: $injByInterface<IWorkspaceStore>("IWorkspaceStore"),
      languageFactory: $inj(LanguageFactory),
      workspaceMetadataStore: $injByInterface<IEmpWorkspaceMetadataStore>("IWorkspaceMetadataStore"),
      broadcastService: $inj(BroadcastService),
      workflowFactory: $inj(EmployeeWorkflowFactory),
      notificationFactory: $injByInterface<INotificationFactory>("INotificationFactory"),
      modalUtil: $inj(ModalUtil),
    };
  },
  async created() {

    this.broadcastService.on(BroadcastConstant.WIZARD_TOGGLE_SEARCH, (showSearchBar: boolean) => {
      this.$emit("searchBtnClicked", showSearchBar || false);
    });
    
    this.assignWizardProps().then(() => {
      this.broadcastService.broadcast(BroadcastConstant.BLTFORM_CREATED_UPDATED, {
        formUpdated: false,
        formValid: !this.continueButtonDisabled
      });
    });

  },
  async mounted() {

    this.$refs.form.$el.addEventListener('input', () => {
      console.log("Recieved input event from form...")
      this.pristine = false;
    });

  },
  async updated() {
    // todo(mikol): this is terrible for perf...
    this.assignWizardProps().then(() => {
      this.broadcastService.broadcast(BroadcastConstant.BLTFORM_CREATED_UPDATED, {
        formUpdated: true,
        formValid: !this.continueButtonDisabled
      });
    });
  },
  methods: {
    submitForm() {

      //todo(mikol): We need to see if the form has been interacted with before triggering the form submission
      // if not, we should just skip to running the rule.
      if(this.pristine) {
        console.log("The form is pristine, skipping validation and moving forward.")
        this.doContinue();
        return
      }

      // todo(mikol): I know this is a little silly, but for now this adds a small
      // buffer where any already existing error messages will go away and then be
      // added again to let the user know that yes, something is STILL wrong. Eventually
      // we should go back to the behavior of clearing the errors when anything in the form
      // is modified.
      setTimeout(() => {
        this.$refs.form.$el.dispatchEvent(new Event('submit', { cancelable: true }))
      }, 100)
    },
    async goToNextState(details: Detail[] = []) {
      this.workflowFactory.nextV2({ validationResponses: details });
      this.pristine = true;
    },
    async doContinue() {

      // todo(mikol): evil global state, destroy this...
      this.notificationFactory.clearMessages();

      this.validating = true;

      // We do not call the before hooks when the applicant or workspace is locked, we just move forward.
      // if this.alwaysValidate is true, we override this.
      if((!this.isInLockedWorkspace && !this.isInExistingUser && !this.isInLockedApplicant) || this.alwaysValidate) {

        console.log(`Running beforeContinue function for state ${this.validation}, `, this.beforeContinue)

        try {
          await this.beforeContinue()
        } catch (e) {
          // todo(mikol): currently error messages are assigned globally / magically in the http response interceptors, we should instead do this explicitly here.
          console.info(`Error occurred during beforeContinue when continuing on the ${this.validation} state, not moving forward..`, e)
          this.validating = false;
          return;
        }

        try {
          await this.validateForm();
        } catch (e) {
          this.modalUtil.prompt("continue", "Are you sure you would like to continue, even though there are errors on this page?")
            .then(() => this.goToNextState())
            .catch(() => { this.notificationFactory.setErrorMessagesFromDetails([{ code: "form", message: "Please correct any errors and try again." }]) })

          console.info(`Error occurred when validating the for for ${this.validation}, not moving forward...`, e)
          this.validating = false;
          this.notificationFactory.setErrorMessagesFromDetails(e as Detail[])
          return;
        }

      }

      try {

        // todo(mikol): revisit this and decouple "Moving Forward" from "Validating All States", this will just get us ready for prod for the time being.
        if(this.validationType === "APPLICANT" ) {
          const validations = await this.applicantValidationFactory.getAll(this.workspaceUuid, this.applicantId as number, ApplicantValidationStates.ALL)
          this.goToNextState(validations);
        } else if(this.validationType === "ENROLLMENT") {
          const validations = await this.enrollmentValidationFactory.getAll(this.workspaceUuid, this.enrollmentId as number, EnrollmentValidationStates.ALL)
          this.goToNextState(validations);
        }

      } catch (e) {
        this.validating = false;
        return;
      }

    },
    validateForm() {
      return this.validationType === FormValidationTypes.ENROLLMENT ? this.validateEnrollmentForm() : this.validateApplicantForm();
    },
    handleInvalidForm() {
      if (this.isInExistingUser || this.isInLockedApplicant) {
        this.notificationFactory.clearMessages();
        this.goToNextState();
        return
      }

      this.notificationFactory.setErrorMessagesFromDetails([{ code: 'form', message: 'Please correct any errors and try again.' }])

      this.modalUtil.prompt('Continue', 'Are you sure you would like to continue without adding required information?')
        .then(() => this.goToNextState())

    },
    validateEnrollmentForm() {

      console.info(`Running enrollment validation for state ${this.validation}...`)

      //todo(mikol): evil casting should be removed when we have time to split this component into two...
      return this.enrollmentValidationFactory
        .getForState(this.workspaceUuid as string, this.enrollmentId as number, this.validation as keyof typeof EnrollmentValidationStates)
        .then((validation) => {
          if(validation.errors.length) {
            return Promise.reject(validation.errors);
          } else {
            return Promise.resolve();
          }
        })

    },
    validateApplicantForm() {

      console.log(`Running applicant validation for state ${this.validation}...`)

      //todo(mikol): evil casting should be removed when we have time to split this component into two...
      return this.applicantValidationFactory
        .getForState(this.workspaceUuid as string, this.applicantId as number, this.validation as keyof typeof ApplicantValidationStates)
        .then((validation) => {
          if(validation.errors.length) {
            return Promise.reject(validation.errors);
          } else {
            return Promise.resolve();
          }
        })
    },
    assignWizardProps(): Promise<void> {

      //todo(mikol): what the 💩
      //todo(mikol): go through and remove anything that is unused, this was copy and pasted from the AC...
      //todo(mikol): one by one, go through and figure out how to remove this global state...
      return new Promise((resolve) => {
        this.workspaceMetadataStore.wizardProps.alternateBackEnabled = this.alternateBackEnabled;
        this.workspaceMetadataStore.wizardProps.alternateBackText = this.alternateBackText;
        this.workspaceMetadataStore.wizardProps.alternateBackFunc = this.alternateBackFunc;
        this.workspaceMetadataStore.wizardProps.continueReplacedWithSecondary = this.continueReplacedWithSecondary;
        this.workspaceMetadataStore.wizardProps.backDisabled = this.backDisabled;
        this.workspaceMetadataStore.wizardProps.disabled = this.disabled;
        this.workspaceMetadataStore.wizardProps.secondaryAction = this.secondaryAction;
        this.workspaceMetadataStore.wizardProps.secondaryActionText = this.secondaryActionText;
        this.workspaceMetadataStore.wizardProps.secondaryActionActive = this.secondaryActionActive;
        this.workspaceMetadataStore.wizardProps.secondaryActionClass = this.secondaryActionClass;
        this.workspaceMetadataStore.wizardProps.continueDisabledSecondary = this.continueDisabledSecondary;

        //==========================================================
        this.workspaceMetadataStore.wizardProps.loading = this.loading || this.validating;
        this.workspaceStore.transitionInfo.preloading = this.loading || this.validating;
        //==========================================================
        this.workspaceMetadataStore.wizardProps.loaderMessages = this.loaderMessages;
        this.workspaceMetadataStore.wizardProps.hideInfoBlock = this.hideInfoBlock;
        this.workspaceMetadataStore.wizardProps.hideNavigation = this.hideNavigation;
        this.workspaceMetadataStore.wizardProps.bltUiText = this.bltUiText;
        this.workspaceMetadataStore.wizardProps.description = this.description;
        this.workspaceMetadataStore.wizardProps.hideBackButton = this.hideBackButton;
        this.workspaceMetadataStore.wizardProps.hideContinueButton = this.hideContinueButton;
        this.workspaceMetadataStore.wizardProps.showDeterminateProgressBar = this.showDeterminateProgressBar;
        this.workspaceMetadataStore.wizardProps.searchIcon = this.searchIcon;
        this.workspaceMetadataStore.wizardProps.innerScreenButtonEnabled = this.innerScreenButtonEnabled;
        this.workspaceMetadataStore.wizardProps.innerScreenBtnFunc = this.innerScreenBtnFunc;
        this.workspaceMetadataStore.wizardProps.contentCardClass = this.contentCardClass;
        this.workspaceMetadataStore.wizardProps.dividerLine = this.dividerLine;
        this.workspaceMetadataStore.wizardProps.hideTitle = this.hideTitle;
        this.workspaceMetadataStore.wizardProps.continue = this.continue;
        this.workspaceMetadataStore.wizardProps.back = this.back;
        this.workspaceMetadataStore.wizardProps.hideContinueLoader = this.hideContinueLoader;
        this.workspaceMetadataStore.wizardProps.contentMarginClass = this.contentMarginClass;
        this.workspaceMetadataStore.wizardProps.staticTitle = this.staticTitle;
        this.workspaceMetadataStore.wizardProps.secondaryTitle = this.secondaryTitle;
        this.workspaceMetadataStore.wizardProps.hideErrorNotification = this.hideErrorNotification;
        this.workspaceMetadataStore.wizardProps.secondaryRightSideText = this.secondaryRightSideText;

        resolve();
      });
    },
    doSecondaryAction(event: Event) {
      if (this.secondaryAction) {
        this.secondaryAction(event);
      }
    }
  },
  computed: {
    currentState: function () {
      const tempcurrentState = this.workspaceStore.workflow.currentState;
      return tempcurrentState || {};
    },
    continueButtonDisabled: function () {
      return this.continueDisabledSecondary || this.loading || this.disabled || this.validating;
    },
    isInLockedWorkspace() {
        return this.workspaceStore.workspace?.statusCode === workspaceStatusConstant.CLOSED
    },
    isInLockedApplicant() {
      return this.validationType === "APPLICANT" && this.workspaceStore.applicant?.locked;
    },
    isInExistingUser() {
      return this.validationType === "APPLICANT" && this.workspaceStore.applicant?.existing
    }
  }
});
</script>
<template>
  <div class="blt-form-header"></div>
  <div class="blt-form-content">
    <blt-form ref="form" @validSubmit="doContinue" @invalid-submit="handleInvalidForm">
      <slot></slot>
    </blt-form>
  </div>
  <div class="blt-form-footer" v-if="!hideContinueButton && !hideNavigation">
    <div class="divider-footer" v-if="dividerLine"></div>
    <jha-button
      id="buttonContinue"
      :sync="hideContinueLoader"
      v-if="!continueReplacedWithSecondary"
      :disabled="continueButtonDisabled"
      aria-label="Continue"
      @click="submitForm()"
      type="submit"
      >Continue
    </jha-button>
    <jha-button
      class="secondaryActionBtn"
      :sync="hideContinueLoader"
      v-if="continueReplacedWithSecondary"
      :class="secondaryActionClass"
      :aria-label="secondaryActionText"
      @click="doSecondaryAction"
      :disabled="continueButtonDisabled"
    >
      {{ secondaryActionText }}
    </jha-button>
  </div>
</template>

<style scoped>
.blt-form-footer {
  padding-top: var(--jh-space-600);
}

.divider-footer {
  border-bottom: 1px solid var(--jha-border-color);
  bottom: 28px;
  background: #f000;
  margin: 0 -52px 16px -52px;
}
@media (max-width: 468px) {
  #buttonContinue,
  .secondaryActionBtn {
    width: 100%;
  }
}
</style>
