<script setup lang="ts">
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {requireInject} from '@/common/utils/InjectUtil'
import {VsgIconSearch, VsgList} from '@jack-henry/jha-vue-components'
import {computed} from 'vue'
import ProductGroupsPaneGroupList from '@/common/components/productSelection/productGroupsPaneGroupList.vue'
import {BltSearch} from '@/common/services/Search/SearchFactory'
import {$inj} from '@/common/decorators/depinject'
import BltEmptyData from '@/common/components/bltEmptyData/bltEmptyData.vue'
import {IAccountTypeGroup} from "@/common/services/Products/accountGroupService";
import ProductGroupsPaneGroupsListTFA from "@/common/components/productSelection/productGroupsPaneGroupsListTfa.vue";
import ProductGroupsPaneGroupsListTfa from "@/common/components/productSelection/productGroupsPaneGroupsListTfa.vue";

const bltSearch = $inj(BltSearch)
const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const productGroups = computed(() => {
  return injection.values.productGroups.value.filter(group => !group.parentGroup)
})

const searchedProductResults = computed(() => {
  return bltSearch.for(
    injection.values.productList.value,
    ['major', 'minor', 'description'],
    injection.values.searchTerm?.value ?? ''
  )
})

const subgroupsSearched = computed(() => {
  let results = [] as Array<IAccountTypeGroup>

  injection.values.productSubgroups.value.forEach((value, key) => {
    results = results.concat(
        bltSearch.for(
            value,
          ['typeCode', 'typeDescription'],
          injection.values.searchTerm?.value ?? ''
        )
    )
  })

  return results
})

const productGroupsExcludingTfa = computed(() => {
  return productGroups.value.filter(group => !['HSA', 'IRA'].includes(group.typeCode))
})
</script>
<template>
  <vsg-list>
    <blt-empty-data
      v-if="!searchedProductResults.length && !subgroupsSearched.length"
      :blt-text="`No matches for ${injection.values.searchTerm?.value}`">
      <template #jhaIcon>
        <vsg-icon-search class="secondary-fill-color" />
      </template>
    </blt-empty-data>
    <div
      v-for="group in productGroupsExcludingTfa"
      :key="group.typeCode">
      <product-groups-pane-group-list :group />
    </div>

    <product-groups-pane-groups-list-tfa/>
  </vsg-list>
</template>

<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';
</style>
