import {SingletonService} from "@/common/dependency.configs";
import {injectable} from "inversify";
import dayjs from "dayjs";
import type {ProductsSubgroupTaxplan} from "@/common/services/Products/productsSubgroupTaxplanService";
import type {FundingIntention} from "@/common/services/Products/fundingIntentionService";

@injectable()
@SingletonService
export class FundingContributionUtils {
    thisYear: number
    lastYear: number

    constructor() {
        this.thisYear = dayjs().get('year')
        this.lastYear = dayjs().subtract(1, 'year').get('year')
    }

    getMaxFundingLimit(
        taxPlan: ProductsSubgroupTaxplan,
        fundingIntention: FundingIntention,
        oldestAge: number
    ) {
        if (!fundingIntention.contributionYear) {
            return null
        }
        else if (fundingIntention.contributionYear == this.thisYear.toString()) {
            if (oldestAge > (taxPlan.currentYearCatchupAge ?? 0)) {
                return taxPlan.currentYearCatchupContribLimit
            } else {
                return taxPlan.currentYearContribLimit
            }
        } else if (fundingIntention.contributionYear == this.lastYear.toString()) {
            if (oldestAge > (taxPlan.previousYearCatchupAge ?? 0)) {
                return taxPlan.previousYearCatchupContribLimit
            } else {
                return taxPlan.previousYearContribLimit
            }
        } else {
            return null
        }
    }
}