import {Service} from '@/common/dependency.configs'
import {inject, injectable} from 'inversify'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'
import type TypeCode from '@/common/data/TypeCode'

@Service
@injectable()
export default class AccountGroupService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(params: Record<string, boolean | string> = {}): Promise<RestResponse<Array<IAccountTypeGroup>>> {
    return this.httpHandler.get(`{{boltsbranch}}/productgroup`, {params})
  }

  getAllWithNested(params: Record<string, boolean | string> = {}): Promise<Array<IAccountTypeGroupWithDisabled>> {
    return this.getAll(params).then(response => {
      const results: Array<IAccountTypeGroupWithDisabled> = []
      const topLevel = response.data.payload.filter(group => !group.parentGroup	)

      return topLevel.reduce((acc, cur) => {
        const childGroups = response.data.payload
            .filter(group => group.parentGroup	 == cur.typeCode)

        acc.push({...cur, disabled: childGroups.length})
        acc = acc.concat(
            response.data.payload
                .filter(group => group.parentGroup	 == cur.typeCode)
                .map(group => ({
                  ...group,
                  typeDescription: `↳ ${group.typeDescription}`
                }))
        )
        return acc;
      }, results)
    })
  }

  update(group: IAccountTypeGroup): Promise<RestResponse<IAccountTypeGroup>> {
    return this.httpHandler.put(`{{boltsbranch}}/productgroup`, group, {
      params: {
        productGroupCode: group.typeCode
      }
    })
  }
}

export interface IAccountTypeGroup extends TypeCode {
  parentGroup?: String
}

export interface IAccountTypeGroupWithDisabled extends IAccountTypeGroup {
  disabled?: boolean
}